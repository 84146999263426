import React from "react"
import slugify from "slugify"
import { IArticleSection } from "../../@types/types"
import Html from "./Html"

type ArticleSectionProps = IArticleSection & {
  index: number
}

const ArticleSummarySection = ({
  index,
  title,
  name,
  blurb,
  published,
}: ArticleSectionProps): JSX.Element => {
  const formattedDate = new Date(published).toLocaleDateString("en-GB", {
    month: "long",
    year: "numeric",
    day: "numeric",
  })
  const colorClass = index % 2 === 0 ? "bg-primary-50" : "bg_white"
  const href = `/article/${slugify(name, { lower: true })}`
  return (
    <section className={`${colorClass} overflow-x-hidden`}>
      <div className="container max-w-4xl mx-auto lg:px-20 xl:px-24 py-8 md:py-10 lg:py-12 xl:py-10">
        <div className="lg:text-center">
          <h1 className="text-3xl font-semibold tracking-wide text-primary-500">
            {title}
          </h1>
          <h2 className="text-lg text-gray-500 sm:text-xl mt-1 mb-3  lg:mx-auto">
            Published - {formattedDate}
          </h2>
        </div>
        <Html html={blurb} />
        <a href={href} className="font-semibold text-primary-500 text-l">
          Read the full article...
        </a>
      </div>
    </section>
  )
}

export default ArticleSummarySection
