import React from "react"
import { ICtaAction, ICTASection } from "../../@types/types"
import CallsToAction from "./CallsToAction"

type CTASectionProps = ICTASection & {
  index?: number
  onAction: (action: ICtaAction) => void
}

const CTASection = ({
  callsToAction,
  onAction,
}: CTASectionProps): JSX.Element => {
  return (
    <section className="container mx-auto py-12 md:py-16 lg:py-20 xl:py-24">
      <CallsToAction
        callsToAction={callsToAction}
        onAction={onAction}
        className="grid gap-8 grid-flow-col auto-cols-max"
      />
    </section>
  )
}

export default CTASection
