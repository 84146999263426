import * as Icons from "@heroicons/react/outline"
import React from "react"

const Heroicon = ({
  icon,
  className,
}: {
  icon?: string
  className?: string
}): JSX.Element | null => {
  if (!icon) return null
  const Icon = Icons[icon as keyof typeof Icons]
  if (!Icon) return null
  return <Icon className={className} />
}

export default Heroicon
