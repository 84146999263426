import { ChatAltIcon } from "@heroicons/react/solid"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { ITestimonialSection } from "../../@types/types"

type TestimonialSectionProps = ITestimonialSection & {
  index?: number
}

const TestimonialSection = ({
  testimonial: { cite, quote, image },
}: TestimonialSectionProps): JSX.Element => {
  const testimonialImage = getImage(image)
  return (
    <section className="bg-secondary-700">
      <div className="container mx-auto relative py-12 md:py-16 lg:py-20 xl:py-24">
        <div className="rounded-md shadow-lg overflow-hidden lg:absolute lg:z-10 lg:inset-y-20 xl:-inset-y-8 lg:left-0 lg:w-1/4">
          {testimonialImage && (
            <GatsbyImage
              className="w-full h-full"
              image={testimonialImage}
              alt={cite}
            />
          )}
        </div>
        <div className="ml-auto mt-8 lg:w-3/4 lg:pl-16 lg:pr-32 lg:mt-0 text-white">
          <blockquote className="text-white text-opacity-75 text-2xl tracking-wide mt-4 lg:mt-8">
            <p>{quote.quote}</p>
            <div className="mt-8 flex items-center space-x-2 opacity-30">
              <ChatAltIcon className="h-12 w-12" />
              <cite className="block text-lg text-white not-italic tracking-normal">
                {cite}
              </cite>
            </div>
          </blockquote>
        </div>
      </div>
    </section>
  )
}

export default TestimonialSection
