import classnames from "classnames"
import React from "react"
import { ICta, ICtaAction } from "../../@types/types"
import instaImage from "../images/Instagram_Glyph_Gradient.png"

const CallsToAction = ({
  callsToAction,
  onAction,
  className = "",
  includeInstagram,
}: {
  callsToAction: ICta[]
  onAction: (action: ICtaAction) => void
  className?: string
  includeInstagram?: boolean
}): JSX.Element => {
  const handleClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    action?: ICtaAction
  ) => {
    if (action) {
      e.preventDefault()
      onAction(action)
    }

    return false
  }

  // TODO - turn instagram link into contentful component.
  return (
    <nav className={className}>
      {callsToAction?.map(({ id, text, background, url, action }) => {
        const ctaClassName = getClassNames(background)
        return (
          <a
            key={id}
            target="__blank"
            onClick={(e) => handleClick(e, action)}
            href={url}
            className={ctaClassName}
          >
            {text}
          </a>
        )
      })}
      {includeInstagram && (
        <a
          key="instagram"
          target="__blank"
          href="https://www.instagram.com/orderwhizz/"
          className={getClassNames("White")}
        >
          Find us @Instagram &nbsp;
          <img src={instaImage} alt={`Instagram logo`} height={28} width={28} />
        </a>
      )}
    </nav>
  )
}

function getClassNames(background: string) {
  return classnames(
    "flex items-center justify-center px-4 py-3 text-base md:px-8 md:py-4 md:text-lg border border-transparent font-medium rounded-md shadow-md cursor-pointer text-center",
    {
      "bg-primary-500 hover:bg-primary-700 text-white": background === "Orange",
      "bg-white hover:bg-gray-50 text-primary-500": background === "White",
      "bg-secondary-500 hover:bg-secondary-700 text-white":
        background === "Blue",
    }
  )
}

export default CallsToAction
