import React from "react"
import slugify from "slugify"
import { ICtaAction, IPageSection } from "../../@types/types"
import CTASection from "./CTASection"
import FeatureSection from "./FeatureSection"
import HeroSection from "./HeroSection"
import MarkdownSection from "./MarkdownSection"
import TestimonialSection from "./TestimonialSection"
import ArticleSummarySection from "./ArticleSummarySection"

type PageSectionProps = IPageSection & {
  index: number
  onAction: (action: ICtaAction) => void
}

const PageSection = ({
  index,
  title,
  content,
  onAction,
}: PageSectionProps): JSX.Element | null => {
  const pageAnchor = slugify(title)

  switch (content.__typename) {
    case "ContentfulHeroSection": {
      return (
        <>
          <span id={pageAnchor} className="invisible"></span>
          <HeroSection {...content} index={index} onAction={onAction} />
        </>
      )
    }
    case "ContentfulFeatureSection": {
      return (
        <>
          <span id={pageAnchor} className="invisible"></span>
          <FeatureSection {...content} />
        </>
      )
    }
    case "ContentfulTestimonialSection": {
      return (
        <>
          <span id={pageAnchor} className="invisible"></span>
          <TestimonialSection {...content} />
        </>
      )
    }
    case "ContentfulMarkdownSection": {
      return (
        <>
          <span id={pageAnchor} className="invisible"></span>
          <MarkdownSection {...content} onAction={onAction} />
        </>
      )
    }
    case "ContentfulCtaSection": {
      return (
        <>
          <span id={pageAnchor} className="invisible"></span>
          <CTASection {...content} onAction={onAction} />
        </>
      )
    }
    case "ContentfulArticleSection": {
      return <ArticleSummarySection {...content} index={index} />
    }
    default: {
      return null
    }
  }
}

export default PageSection
