import React from "react"
import { ICtaAction, IMarkdownSection } from "../../@types/types"
import CallsToAction from "./CallsToAction"
import Html from "./Html"

type MarkdownSectionProps = IMarkdownSection & {
  index?: number
  onAction: (action: ICtaAction) => void
}

const MarkdownSection = ({
  content,
  callsToAction,
  onAction,
}: MarkdownSectionProps): JSX.Element => {
  return (
    <section className="container mx-auto py-12 md:py-16 lg:py-20 xl:py-24">
      <div className="lg:flex lg:space-x-16">
        <Html
          className="markdown lg:w-2/3"
          html={content.childMarkdownRemark.html}
        />
        <CallsToAction
          className="mt-12 flex items-center space-x-4 lg:w-1/3 lg:mt-0 lg:justify-end"
          callsToAction={callsToAction}
          onAction={onAction}
        />
      </div>
    </section>
  )
}

export default MarkdownSection
