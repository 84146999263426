import classnames from "classnames"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { ICtaAction, IHeroSection } from "../../@types/types"
import CallsToAction from "./CallsToAction"
import Html from "./Html"

type HeroSectionProps = IHeroSection & {
  onAction: (action: ICtaAction) => void
  index: number
}

const HeroSection = ({
  index,
  orientation,
  heading1,
  heading2,
  introduction,
  callsToAction,
  heroImage,
  onAction,
}: HeroSectionProps): JSX.Element => {
  const contentWrapperClassName = classnames("relative z-10 lg:w-1/2", {
    "pt-6 md:pt-10 lg:pt-28 xl:pt-32 pb-8 md:pb-10 lg:pb-12 xl:pb-14":
      index === 0,
    "py-12 md:py-16 lg:py-20 xl:py-24": index !== 0,
    "lg:pr-24": orientation,
    "lg:pl-24 ml-auto": !orientation,
  })
  const svgMaskClassName = classnames(
    "hidden lg:block absolute inset-y-0 h-full w-48 text-white transform",
    {
      "right-0 translate-x-1/2": orientation,
      "left-0 -translate-x-1/2": !orientation,
    }
  )
  const svgPoints = classnames({
    "50,0 100,0 50,100 0,100": orientation,
    "0,0 50,0 100,100 50,100": !orientation,
  })
  const imageWrapperClassName = classnames(
    "lg:absolute lg:inset-y-0 lg:w-1/2",
    {
      "lg:right-0": orientation,
      "lg:left-0": !orientation,
    }
  )
  const image = getImage(heroImage)
  return (
    <section className="container mx-auto">
      <div className="relative bg-white overflow-hidden">
        <div className={contentWrapperClassName}>
          <svg
            className={svgMaskClassName}
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points={svgPoints} />
          </svg>
          <main className="text-center lg:text-left">
            <header className="html">
              <h1>
                <span className="text-black">{heading1}</span>
                <span className="text-primary-500 block">{heading2}</span>
              </h1>
            </header>
            <Html
              className="hero-section__body mt-4 md:mt-6 text-lg text-gray-500 sm:text-xl max-w-xl mx-auto lg:mx-0"
              html={introduction.childMarkdownRemark.html}
            />
            {callsToAction?.length > 0 && (
              <CallsToAction
                className="mt-6 flex items-center justify-center space-x-4 lg:justify-start"
                callsToAction={callsToAction}
                onAction={onAction}
                includeInstagram
              />
            )}
          </main>
        </div>
        <div className={imageWrapperClassName}>
          {image && (
            <GatsbyImage
              className="w-full h-full"
              image={image}
              alt="Hero Image"
            />
          )}
        </div>
      </div>
    </section>
  )
}

export default HeroSection
