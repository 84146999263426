import React from "react"
import { IFeatureSection } from "../../@types/types"
import Heroicon from "./Heroicon"

type FeatureSectionProps = IFeatureSection & {
  index?: number
}

const FeatureSection = ({
  title,
  heading,
  description,
  features,
}: FeatureSectionProps): JSX.Element => {
  return (
    <section className="bg-primary-50 overflow-x-hidden">
      <div className="container mx-auto lg:px-20 xl:px-24 py-12 md:py-16 lg:py-20 xl:py-24">
        <div className="lg:text-center">
          <h1 className="text-3xl font-semibold tracking-wide uppercase text-primary-500">
            {title}
          </h1>
          <h2 className="mt-2 text-2xl font-bold tracking-tight sm:text-4xl">
            {heading}
          </h2>
          <p className="text-lg text-gray-500 sm:text-xl mt-4 max-w-2xl lg:mx-auto">
            {description.description}
          </p>
        </div>
        <div className="mt-12">
          <dl className="space-y-16 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-16 md:gap-y-16">
            {features?.map(({ id, title, description, icon }, index) => {
              return (
                <div
                  className="relative"
                  key={id}
                  data-aos={index % 2 === 0 ? "fade-right" : "fade-left"}
                >
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary-500 text-white">
                      <Heroicon icon={icon} className="w-6 h-6" />
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium">
                      {title}
                    </p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    {description?.description}
                  </dd>
                </div>
              )
            })}
          </dl>
        </div>
      </div>
    </section>
  )
}

export default FeatureSection
